import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ViewConstants } from '../../shared/shared.constants';
import { ScreenMessages } from '../../shared/master-data/messages';
import { Logger } from '../../shared/components/helpers/Logger';
import * as viewModels from '../../services/view-model/view-models';

@Injectable()
export class SharedDataService {
  // To set the page title
  pageTitle: string;
  // To set the page title
  currentScreenName: string;
  // To set the dealer name
  dealerName: string;
  // To set the dealer name
  dealerNameCopy: string;
  // To set the dealer code
  dealerCode: string;
  // To check isPageEdited
  isPageEdited: boolean;

  currentMenuBarName: string;

  // brand
  brandName: string;
  // Variable is used to increment/decrement the service calls made
  // so that when this value is 0, then the loader icon can be made false
  serviceCallCount: number = 0;
  // Variable is used to display loader icon in the screen
  showLoader: boolean = false;
  // Application Title
  applicationTitle: string;

  // User Id
  userId: string;

  // User Name
  userName: string;
  // User Name Copy
  userNameCopy: string;

  // User Id access token
  accessToken: string;

  retailDate: string;

  public readWriteAccess: boolean = false;

  public vehicleDecertificationAcccess: boolean = false;

  public dealerParticipantScreenAccess: boolean = false;

  public inventoryScreenAccess: boolean = false;

  public rdrScreenAccess: boolean = false;

  public adminScreenAccess: boolean = false;

  public superAdminScreenAccess: boolean = false;

  public isTMEXUser: boolean = false;

  public isSETUser = false;

  public isGSTUser = false;

  public paginationCount: number = 1;

  public isNextVisible: boolean = true;

  public rdrData: viewModels.RdrDetailView = <viewModels.RdrDetailView>new Object();

  public dealerData: any;
  public regionObjective: Array<any> = [];
  public dealerObjective: Array<any> = [];

  /**
   * Variable is used to can Rethrow the error message
   */
  public canRethrowError: boolean = false;

  public canMoveScrollToTop: boolean = false;
  // Variable is used to store selected vehicles
  public selectedVehicles: any;

  public selectedDealer: any;

  public reversalCodeitems: Array<any> =
    [
      {
        name: '21 - REPORTED A WRONG VEHICLE', desc: 'REPORTED A WRONG VEHICLE',
        value: '21', disabled: false
      },
      {
        name: '22 - CUSTOMER RETURNED VEHICLE', desc: 'CUSTOMER RETURNED VEHICLE',
        value: '22', disabled: false
      },
      {
        name: '99 - NO CREDIT, TERMINATE AN AGREEMENT', desc: 'NO CREDIT, TERMINATE AN AGREEMENT',
        value: '99', disabled: true
      },
      { name: '23 - OTHERS, NO CREDIT', value: '23', desc: 'OTHERS, NO CREDIT', disabled: true },
    ];

  public stateItems: Array<any> = [
    { name: 'ALABAMA', value: 'ALABAMA', disabled: false },
    { name: 'ALASKA', value: 'ALASKA', disabled: false },
    { name: 'ARIZONA', value: 'ARIZONA', disabled: false },
    { name: 'ARKANSAS', value: 'ARKANSAS', disabled: false },
    { name: 'CALIFORNIA', value: 'CALIFORNIA', disabled: false },
    { name: 'COLORADO', value: 'COLORADO', disabled: false },
    { name: 'CONNECTICUT', value: 'CONNECTICUT', disabled: false },
    { name: 'DELAWARE', value: 'DELAWARE', disabled: false },
    { name: 'DISTRICT OF COLUMBIA', value: 'DISTRICT_OF_COLUMBIA', disabled: false },
    { name: 'FLORIDA', value: 'FLORIDA', disabled: false },
    { name: 'GEORGIA', value: 'GEORGIA', disabled: false },
    { name: 'IDAHO', value: 'IDAHO', disabled: false },
    { name: 'ILLINOIS', value: 'ILLINOIS', disabled: false },
    { name: 'INDIANA', value: 'INDIANA', disabled: false },
    { name: 'IOWA', value: 'IOWA', disabled: false },
    { name: 'KANSAS', value: 'KANSAS', disabled: false },
    { name: 'KENTUCKY', value: 'KENTUCKY', disabled: false },
    { name: 'LOUISIANA', value: 'LOUISIANA', disabled: false },
    { name: 'MAINE', value: 'MAINE', disabled: false },
    { name: 'MARYLAND', value: 'MARYLAND', disabled: false },
    { name: 'MASSACHUSETTS', value: 'MASSACHUSETTS', disabled: false },
    { name: 'MICHIGAN', value: 'MICHIGAN', disabled: false },
    { name: 'MINNESOTA', value: 'MINNESOTA', disabled: false },
    { name: 'MISSISSIPPI', value: 'MISSISSIPPI', disabled: false },
    { name: 'MISSOURI', value: 'MISSOURI', disabled: false },
    { name: 'MONTANA', value: 'MONTANA', disabled: false },
    { name: 'NEBRASKA', value: 'NEBRASKA', disabled: false },
    { name: 'NEVADA', value: 'NEVADA', disabled: false },
    { name: 'NEW HAMPSHIRE', value: 'NEW_HAMPSHIRE', disabled: false },
    { name: 'NEW JERSEY', value: 'NEW_JERSEY', disabled: false },
    { name: 'NEW MEXICO', value: 'NEW_MEXICO', disabled: false },
    { name: 'NEW YORK', value: 'NEW_YORK', disabled: false },
    { name: 'NORTH CAROLINA', value: 'NORTH_CAROLINA', disabled: false },
    { name: 'NORTH DAKOTA', value: 'NORTH_DAKOTA', disabled: false },
    { name: 'OHIO', value: 'OHIO', disabled: false },
    { name: 'OKLAHOMA', value: 'OKLAHOMA', disabled: false },
    { name: 'OREGON', value: 'OREGON', disabled: false },
    { name: 'PENNSYLVANIA', value: 'PENNSYLVANIA', disabled: false },
    { name: 'RHODE ISLAND', value: 'RHODE_ISLAND', disabled: false },
    { name: 'SOUTH CAROLINA', value: 'SOUTH_CAROLINA', disabled: false },
    { name: 'SOUTH DAKOTA', value: 'SOUTH_DAKOTA', disabled: false },
    { name: 'TENNESSEE', value: 'TENNESSEE', disabled: false },
    { name: 'TEXAS', value: 'TEXAS', disabled: false },
    { name: 'UTAH', value: 'UTAH', disabled: false },
    { name: 'VERMONT', value: 'VERMONT', disabled: false },
    { name: 'VIRGINIA', value: 'VIRGINIA', disabled: false },
    { name: 'WASHINGTON', value: 'WASHINGTON', disabled: false },
    { name: 'WEST VIRGINIA', value: 'WEST_VIRGINIA', disabled: false },
    { name: 'WISCONSIN', value: 'WISCONSIN', disabled: false },
    { name: 'WYOMING', value: 'WYOMING', disabled: false },
    { name: 'AGUASCALIENTES', value: 'Aguascalientes', disabled: true },
    { name: 'BAJA CALIFORNIA NORTE', value: 'Baja California Norte', disabled: true },
    { name: 'BAJA CALIFORNIA SUR', value: 'Baja California Sur', disabled: true },
    { name: 'CAMPECHE', value: 'Campeche', disabled: true },
    { name: 'COAHUILA', value: 'Coahuila', disabled: true },
    { name: 'COLIMA', value: 'Colima', disabled: true },
    { name: 'CHIAPAS', value: 'Chiapas', disabled: true },
    { name: 'CHIHUAHUA', value: 'Chihuahua', disabled: true },
    { name: 'CIUDAD DE MEXICO', value: 'Ciudad de Mexico', disabled: true },
    { name: 'DISTRITO FEDERAL', value: 'Distrito Federal', disabled: true },
    { name: 'DURANGO', value: 'Durango', disabled: true },
    { name: 'GUANAJUATO', value: 'Guanajuato', disabled: true },
    { name: 'GUERRERO', value: 'Guerrero', disabled: true },
    { name: 'HIDALGO', value: 'Hidalgo', disabled: true },
    { name: 'JALISCO', value: 'Jalisco', disabled: true },
    { name: 'ESTADO DE MEXICO', value: 'Estado de Mexico', disabled: true },
    { name: 'MICHOACAN', value: 'Michoacan', disabled: true },
    { name: 'NAYARIT', value: 'Nayarit', disabled: true },
    { name: 'NUEVO LEON', value: 'Nuevo Leon', disabled: true },
    { name: 'OAXACA', value: 'Oaxaca', disabled: true },
    { name: 'PUEBLA', value: 'Puebla', disabled: true },
    { name: 'QUERETARO', value: 'Queretaro', disabled: true },
    { name: 'QUINTANA ROO', value: 'Quintana Roo', disabled: true },
    { name: 'SAN LUIS POTOSI', value: 'San Luis Potosi', disabled: true },
    { name: 'SINALOA', value: 'Sinaloa', disabled: true },
    { name: 'SONORA', value: 'Sonora', disabled: true },
    { name: 'TABASCO', value: 'Tabasco', disabled: true },
    { name: 'TAMAULIPAS', value: 'Tamaulipas', disabled: true },
    { name: 'TLAXCALA', value: 'Tlaxcala', disabled: true },
    { name: 'VERACRUZ', value: 'Veracruz', disabled: true },
    { name: 'YUCATAN', value: 'Yucatan', disabled: true },
    { name: 'ZACATECAS', value: 'Zacatecas', disabled: true },
    { name: 'MORELOS', value: 'Morelos', disabled: true }
  ];

  public suffixItems: Array<any> = [
    { name: 'Jr', value: 'Jr', disabled: false },
    { name: 'Sr', value: 'Sr', disabled: false },
    { name: 'DDS', value: 'DDS', disabled: false },
    { name: 'DMD', value: 'DMD', disabled: true },
    { name: 'DVM.', value: 'DVM', disabled: false },
    { name: 'ESQ', value: 'ESQ', disabled: false },
    { name: 'JD', value: 'JD', disabled: false },
    { name: 'MD', value: 'MD', disabled: false },
    { name: 'PHD', value: 'PHD', disabled: false },
    { name: 'I', value: 'I', disabled: true },
    { name: 'II', value: 'II', disabled: false },
    { name: 'III', value: 'III', disabled: false },
    { name: 'IV', value: 'IV', disabled: false },
    { name: 'V', value: 'V', disabled: true },
    { name: 'VI', value: 'VI', disabled: false },
    { name: 'VII', value: 'VII', disabled: false },
    { name: 'VIII', value: 'VIII', disabled: false },
    { name: 'IX', value: 'IX', disabled: false },
    { name: 'X', value: 'X', disabled: false }
  ];

  public salutationItems: Array<any> = [
    { name: 'Mr.', value: 'Mr', disabled: false },
    { name: 'Sr.', value: 'Sr', disabled: false },
    { name: 'Mrs.', value: 'Mrs', disabled: false },
    { name: 'Sra.', value: 'Sra', disabled: false },
    { name: 'Ms.', value: 'Ms', disabled: true },
    { name: 'Srita.', value: 'Srita', disabled: false },
    { name: 'Miss', value: 'Miss', disabled: false },
    { name: 'Co', value: 'Co', disabled: false },
    { name: 'Dr', value: 'Dr', disabled: false },
    { name: 'Mr&Mrs.', value: 'Mr&Mrs', disabled: false },
    { name: 'Sr&Sa.', value: 'Sr&Sa', disabled: false },
    { name: 'Rev.', value: 'Rev', disabled: false },
    { name: 'Did Not Provide', value: 'DNP', disabled: false }
  ];

  public salutationItemsForSET: Array<any> = [
    { name: 'Mr.', value: 'Mr', disabled: false },
    { name: 'Mrs.', value: 'Mrs', disabled: false },
    { name: 'Ms.', value: 'Ms', disabled: true },
    { name: 'Miss', value: 'Miss', disabled: false },
  ];

  public salesCodeItems: Array<any> =
    [
      { name: '1 Purchase - Individual', value: '01', disabled: false },
      { name: '3 Purchase - Business', value: '03', disabled: false },
      { name: '5 Lease - Individual', value: '05', disabled: false },
      { name: '7 Sold for Resale', value: '07', disabled: true },
    ];

    public salesCodeItemsSET: Array<any> =
    [
      { name: '1 Purchase - Individual', value: '01', disabled: false }
    ];

  public monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  get viewConstants() {
    return ViewConstants;
  }
  /***
   * Method to set Selected Vehicle
   */
  setSelectedVehicle(selectedVehicles: any) {
    this.selectedVehicles = cloneDeep(selectedVehicles);
  }
  /***
  * Method to Set Selected Vehicle
  */
  getSelectedVehicle() {
    return cloneDeep(this.selectedVehicles);
  }

  /**
   * Get proprty is used to get the screen messages
   */
  get screenMessages() {
    return ScreenMessages;
  }

  getReversalCodeDesc(code: string) {
    this.reversalCodeitems.forEach(dd => {
      if (dd.value === code) {
        return dd.desc;
      } else {
        return '';
      }
    });
  }

  /***
   * Constructor for Shared Data Service
   */
  constructor(public logger: Logger) {
  }


  public checkForExtendedWindow(modelYear: number, mileage: number) {


    const eligibleTiers = [];

    if(!modelYear || !mileage) {
      return eligibleTiers
    }


    const goldMileageValidity = mileage > 0 && mileage <= 85000;
    const silerMileageValidity = mileage >= 60000 && mileage <= 125000;

    if(this.checkForExtendedModelYear(Number(modelYear) + 6) && goldMileageValidity) {
      eligibleTiers.push('GOLD');
    }

    if(this.checkForExtendedModelYear(Number(modelYear) + 10) && silerMileageValidity) {
      eligibleTiers.push('SILVER');
    }

    return eligibleTiers;
  
    
  }


  private checkForExtendedModelYear(tierEligibilityEndYear: number) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    if ((currentYear <= tierEligibilityEndYear) || (currentYear === tierEligibilityEndYear + 1 && currentMonth === 0)) {
      return true;
    } 
    return false;
  } 

}
